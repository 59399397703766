<template>
    <div class="px-3 py-2">
        <b-sidebar backdrop id="renewalPage" width="90rem" saria-labelledby="sidebar-no-header-title" no-header style="direction:ltr;" right title="اضافة مهمة" shadow >
            <template>
                <div style="height:99vh;width:100%;display:flex;flex-direction:column;justify-content:space-between;justify-items:center;align-items:center">
                    <div style="width:90%;margin:10px;display:flex;justify-content:space-between;justify-items:center;align-items:center;">
                        <div><button class="p-1" @click="closeMe()" style="color:#fff;border:none !important;background:red;width:200px;font-size:1.2rem;" v-b-toggle.renewalPage>{{lang.close}}</button></div>
                        <div><button class="p-1" @click="viewInvoice()" style="color:#fff;border:none !important;background:green;width:200px;font-size:1.2rem;">{{lang.view_invoice}}</button></div>
                    </div>
                    <div style="min-height:400px;text-align:center;width:70%;display:flex;flex-direction:column;justify-content:space-between;justify-items:center;align-items:center;">
                        <div style="font-size:2rem;direction:rtl"><p style="font-size:2rem;">{{ messageTitle }}</p></div>
                        <div style="direction:rtl;margin:auto;width:700px;text-align:center;line-break: anywhere;white-space:pre-line;">
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th style="background:#000;color:#fff">{{ lang.start_date }}</th>
                                        <td style="background:green;color:#fff">{{ start_date }}</td>
                                        <th style="background:#000;color:#fff">{{ lang.expire_date }}</th>
                                        <td style="background:red;color:#fff">{{ expire_date }}</td>
                                        <th style="background:#000;color:#fff">{{ lang.amount }}</th>
                                        <td style="background:red;color:#fff">{{ price }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" style="background:#FFF;line-break: anywhere;white-space:pre-line;">
                                            <div>
                                                عزيزنا العميل : {{ customer_name }}
                                            </div>
                                            <div style="font-size:1.2rem;background:#f9ff9e;margin:3px;border:1px solid #000;">
                                                {{ message }}
                                            </div>
                                            
                                        </td>
                                    </tr>
                                </thead>
                            </b-table-simple>
                            <iframe id="closeM" style="width:0;height:0;border:none;" src=""></iframe>
                        </div>
                        
                    </div>
                    <div><img :src="`${footerURL}`" style="width:100%;"></div>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            showImg: true,
            imgURL: '',
            message: '',
            footerURL: '',
            messageTitle: '',
            start_date: '',
            expire_date: '',
            customer_name: '',
            price: '',
            myId: 0
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
    methods: {
        closeMe(){
            document.getElementById('closeM').setAttribute("src","https://bill.samcsoft.com/api/print.php?updateMe="+this.myId)
        },
        viewInvoice(){
            window.open("https://bill.samcsoft.com/api/print.php?renewMe="+this.myId,"_blank");
        },
        getMessageDetails()
        {
            const post = new FormData();
            post.append('type','getRenewal');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[message]','renewal');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.showImg = res.results.data.showImg;
                this.imgURL = res.results.data.imgURL;
                this.footerURL = res.results.data.footerURL;
                this.showImg = res.results.data.showImg;
                this.messageTitle = res.results.data.messageTitle;
                this.message = res.results.data.message;
                this.start_date = res.results.data.start_date;
                this.expire_date = res.results.data.expire_date;
                this.customer_name = res.results.data.customer_name;
                this.price = parseFloat(res.results.data.price);
                this.myId = parseFloat(res.results.data.myId);
            })
        }
        
    },
    created() {
        this.getMessageDetails();
    }
}
</script>
